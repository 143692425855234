// i18next-extract-mark-ns-start apple-pay-for-shopify
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import shopify_applepay from 'images/shopify_apple-pay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';

import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const ApplePayForShopify: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="apple-pay-for-shopify"
        title={t('Shopify Apple Pay')}
        description={t(
          'Add Apple Pay to your Shopify store to let iOS users pay with one of the most used digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Add Apple Pay to your Shopify store</Trans>
              </SectionHeader>
              <Trans parent="p">
                Apple Pay has over 500 million users worldwide and it’s growing every day. That’s
                why it’s important to add Apple Pay to your Shopify store. To do this, you’ll need a
                payment service provider that supports Apple Pay payments and integrates with
                Shopify.
              </Trans>
              <Trans parent="p">
                Use our advanced e-commerce payment gateway to accept and manage all the most
                popular payment methods in a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={shopify_applepay} alt="MONEI apple pay" title="MONEI apple pay" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept Apple Pay and many other Shopify payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding Apple Pay to your Shopify store will help you reach more customers, but it
                shouldn’t be the only method of payment you accept. Today, online shoppers want many
                payment options to choose from. This includes{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , more digital wallets like{' '}
                <InternalPageLink slug="payment-methods/paypal">Paypal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                as well as <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                such as <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in
                Spain.
              </Trans>
              <Trans parent="p">
                Accepting more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> will
                help you improve customer satisfaction and boost sales. That’s why we aggregate all
                major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into a single platform to help you grow your e-commerce business faster.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Increase sales by approving more transactions</Trans>
              </SectionHeader>
              <Trans parent="p">
                Create a better checkout experience, approve more payments, and sell more thanks to
                our payments orchestration feature. Instead of relying solely on{' '}
                <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink>, you’ll be
                able to choose a default payment processor, and if your provider is experiencing
                downtime, we’ll route the transaction to another one. This will reduce the
                likelihood of{' '}
                <BlogLink slug="online-payment-failure-message">online payment failure</BlogLink>{' '}
                messages and lost sales, and give shoppers a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the lowest Shopify payment gateway pricing</Trans>
              </SectionHeader>
              <Trans parent="p">
                We want to help you grow your online store, that’s why we’re the only payment
                gateway that scales with your business. Sell more and spend less on transaction fees
                with our dynamic pricing model.
              </Trans>
              <Trans parent="p">
                Choose the most flexible payment gateway —{' '}
                <Link to="/pricing/">view pricing here</Link>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to add Apple Pay to your Shopify store</Trans>
            </SectionHeader>
            <Trans parent="p">
              Quickly and easily connect your Shopify store with MONEI in a few short steps to start
              accepting Apple Pay payments (and more) today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/sections/360005144598-Apple-Pay">
                  Configure Apple Pay payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/">
                  Connect MONEI
                </AnchorLink>{' '}
                to your Shopify store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default ApplePayForShopify;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "apple-pay-for-shopify"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
